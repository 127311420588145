import { useEffect, useState } from 'react';
import Splash from './splash/Splash';
import useStore from '@/store';
import { logout } from '@/services/authService';
import useExpiryStore from '@/store/expiryStorage';

const Logout = () => {
  const { setAuthToken } = useStore((state) => state);
  const { setPaginationDeal } = useExpiryStore((state) => state);
  const [loggedOut, setLoggedOut] = useState(false);

  useEffect(() => {
    (async () => {
      await logout();
      setAuthToken(null);
      setPaginationDeal([]);
      setLoggedOut(true);
      localStorage.removeItem('userRoles');
      localStorage.removeItem('userPermissions');
      localStorage.clear();
    })();
    return () => {
      setLoggedOut(false);
    };
  }, []);

  return loggedOut ? <Splash logoutSplash suppressInstallPrompt /> : null;
};

export default Logout;
