import { CHECK_USER_PERMISSION_API_URL } from '@/config/config';
import { usePasswordless } from 'amazon-cognito-passwordless-auth/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from '../../store';

/**
 * usePasswordless() parameters available in below link
 * @returns https://github.com/aws-samples/amazon-cognito-passwordless-auth/blob/21f72ff6c71a09928fbd43d9af3c68d952881844/client/react/README-REACT.md
 */

function AWSCognito() {
  const navigate = useNavigate();
  const { signInStatus, tokensParsed, tokens } = usePasswordless();

  const [showStepUpAuth, setShowStepUpAuth] = useState(false);
  if (showStepUpAuth && signInStatus !== 'SIGNED_IN') setShowStepUpAuth(false);
  const setAuthToken = useStore((state) => state.setAuthToken);

  useEffect(() => {
    const handleLogin = async () => {
      // setIsSubmitting(true);
      if(tokensParsed && tokens) {
          const cognitoID = tokensParsed.idToken['cognito:username'];
          fetch(`${CHECK_USER_PERMISSION_API_URL}?cognitoID=${cognitoID}`)
            .then((response) => response.json())
            .then((authData) => {
              if (authData.statusCode === 200) {
                const { roles, permissions }: { roles: string[], permissions: string[] } = authData.body;
                localStorage.setItem('userRoles', JSON.stringify(roles));
                localStorage.setItem('userPermissions', JSON.stringify(permissions));

                if (tokens && tokens.accessToken) {
                  setAuthToken(tokens.accessToken);
                }
    
                if (roles.includes('admin')) {
                  navigate('/v2/deals');
                } else if (roles.includes('selling')) {
                  navigate('/offers');
                } else if (roles.includes('buying')) {
                  navigate('/buyers');
                }
    
                // setIsSubmitting(false);
              }
            })
            .catch((error) => {
              console.error('Error:', error);
              //   setIsSubmitting(false);
            });
      }
    };

    handleLogin();
  }, [tokensParsed, tokens]);

  return (
    <div className="app">
      
    </div>
  );
}

export default AWSCognito;
