import { Checkbox } from '@/components/ui/checkbox';
import { getCurrentSession, logInWithEmailAndPassword } from '@/services/authService';
import { EnvelopeClosedIcon, EyeNoneIcon, EyeOpenIcon, LockClosedIcon } from '@radix-ui/react-icons';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import useStore from '../../store';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import './Login.css';
// import building from '@/assets/Icon-Building3.webp';
// import building from '@/assets/astronaut2.webp';
import building from '@/assets/map-hi.webp';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnimatePresence, motion } from 'framer-motion';
import * as Yup from 'yup';
import { useAuth } from '../AuthProvider';
import DisableScrollY from '../DisableScrollY';
import Splash from '../splash/Splash';
// import FilterButtonSort from '../dashboard/common/FilterButtonSort';
import { CHECK_USER_PERMISSION_API_URL } from '@/config/config';
import AWSCognitoMain from '../aws-cognito/aws-cognito-main';
import WhiteIconLogo from '../icons/IconLogo-wh';

// import { set } from 'node_modules/ts-pattern/dist/patterns';
type Inputs = {
  email: string;
  password: string;
};

const Login = () => {
  const [type, setType] = useState('password');
  const [, setIcon] = useState('EyeOpenIcon');
  const [earlyAccessDlgOpen, setEarlyAccessDlgOpen] = useState(false);
  const [magicLinkDlg, setMagicLinkDlg] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const authHook = useAuth();
  const authToken = useStore((state) => state.auth);
  const loadingTimeout = React.useRef<number | NodeJS.Timeout | undefined>(undefined);

  const handleToggle = () => {
    if (type === 'password') {
      setIcon('EyeOpenIcon');
      setType('text');
    } else {
      setIcon('EyeNoneIcon');
      setType('password');
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email is invalid'),
    password: Yup.string().required('Password is required'),
  });
  const setAuthToken = useStore((state) => state.setAuthToken);
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: 'all',
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const handleAuth = async (data: Inputs) => {
    setIsSubmitting(true);
    const isSignedIn = await logInWithEmailAndPassword(data.email, data.password);
    if (isSignedIn) {
      const currentSession = await getCurrentSession();
      const cognitoID = currentSession?.idToken?.payload['cognito:username'];
      fetch(`${CHECK_USER_PERMISSION_API_URL}?cognitoID=${cognitoID}`)
        .then((response) => response.json())
        .then((authData) => {
          if (authData.statusCode === 200) {
            const { roles, permissions }: { roles: string[], permissions: string[] } = authData.body;
            localStorage.setItem('userRoles', JSON.stringify(roles));
            localStorage.setItem('userPermissions', JSON.stringify(permissions));
            // console.log('>>>>>>>>>> currentSession.idToken' , currentSession.idToken);
            
            if (currentSession.idToken) {
              setAuthToken(currentSession.idToken.toString());
            }

            if (roles.includes('admin')) {
              navigate('/v2/deals');
            } else if (roles.includes('selling')) {
              navigate('/offers');
            } else if (roles.includes('buying')) {
              navigate('/buyers');
            }
            setIsSubmitting(false);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          setIsSubmitting(false);
        });

      // const currentSession = await getCurrentSession();
      // if (currentSession.idToken) {
      //   setAuthToken(currentSession.idToken.toString());
      // }
      // setIsSubmitting(false);
      // navigate('/v2/deals');
    } else {
      setIsSubmitting(false);
      alert('Invalid credentials');
    }
  };
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await handleAuth(data);
  };
  // const items = { ...localStorage };

  // useEffect(() => {
  //   if(authToken !== null && location.pathname !== "/aws-login") {
  //     // navigate('/aws-login', { replace: true });
  //     // window.location.reload()
  //     setMagicLinkDlg(true)
  //   } else {
  //     // setMagicLinkDlg(false)
  //   }
  // }, [items, authToken, location.pathname]);


  useEffect(() => {
    clearTimeout(loadingTimeout.current);
    if (authHook === undefined) {
      loadingTimeout.current = setTimeout(() => setLoading(true), 500);
    } else if (authHook === null || !authToken) {
      loadingTimeout.current = setTimeout(() => setLoading(false), 1000);
    } else {
      navigate('/v2/deals', { replace: true });
    }
  }, [authHook]);

  return (
    <>
      <DisableScrollY>
        {loading && <Splash suppressInstallPrompt logoutSplash />}
        {!loading ? (
          <AnimatePresence>
            <motion.div
              className="flex h-full flex-col bg-auto bg-center"
              style={{ backgroundImage: `url(${building})` }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              key="loginBox"
            >
              <div className="flex h-full w-full items-center">
                <div className="flex w-2/5 flex-col justify-center">
                  <div className="mx-auto my-0 text-base font-normal">
                    <div>
                      <WhiteIconLogo />
                    </div>
                    <div className="empower-your-real mx-auto text-left uppercase xl:ml-24">
                      <p className="text-[#f3f0be]">Empower</p>
                      <p className="text-white">your real estate decisions with </p>
                      <p className="text-[#fdf6c6]">advanced AI</p>
                      <p className="text-white">driven insights</p>
                    </div>
                  </div>
                </div>
                <div className="flex h-full w-3/5 flex-col justify-center">
                  <div className="login-form-container flex items-center justify-center">
                    <div className="login-form-wrapper px-5">
                      <Dialog open={earlyAccessDlgOpen}>
                        <DialogContent className="max-w-[90vw] lg:max-w-xl">
                          <DialogHeader>
                            <DialogTitle>Early Access</DialogTitle>
                          </DialogHeader>
                          <p>Early access is not available yet. Please try back later. If you already have an account, please reach out to Technical Support.</p>
                          <DialogFooter>
                            <Button onClick={() => setEarlyAccessDlgOpen(false)}>OK</Button>
                          </DialogFooter>
                        </DialogContent>
                      </Dialog>
                      <h1 className="welcome-back-title mt-5 text-center">Welcome Back!</h1>
                      <p className="sub-title text-center">We’re glad to see you.</p>
                      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                        <div className="mt-5 flex flex-col">
                          <Input
                            size="lg"
                            iconStart={<EnvelopeClosedIcon width={18} height={18} />}
                            placeholder="Email"
                            id="email"
                            {...register('email')}
                            className="h-14 border-[1px] border-customGrayB9B9B9 text-customGrayB9B9B9 dark:bg-customGray656A72 dark:text-white"
                            customInputClasses="placeholder-black dark:placeholder-white text-sm text-black dark:text-white"
                          />
                          <div className="mb-3 mt-2 text-sm text-red-500"> {errors.email && errors.email.message}</div>
                          <div className="relative flex h-14 overflow-hidden rounded-lg bg-textbox text-lg font-light ring-offset-background placeholder:text-muted-foreground  focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                            <Input
                              size="lg"
                              iconStart={<LockClosedIcon width={18} height={18} />}
                              placeholder="Password"
                              type={type}
                              id="password"
                              {...register('password')}
                              className="h-14 border-[1px] border-customGrayB9B9B9 text-customGrayB9B9B9 dark:bg-customGray656A72 dark:text-white"
                              customInputClasses="placeholder-black dark:placeholder-white text-sm text-black dark:text-white"
                            />
                            <div className="absolute right-0 top-0 flex h-full items-center pr-4" onClick={handleToggle}>
                              {type === 'password' ? <EyeOpenIcon /> : <EyeNoneIcon />}
                            </div>
                          </div>
                          <div className="mb-3 mt-2 text-sm text-red-500"> {errors.password && errors.password.message}</div>
                        </div>
                        <div className="mb-8 flex w-full items-center justify-between">
                          <div className="flex items-center space-x-2">
                            <Checkbox id="terms1" className="h-[18px] w-[18px] border-2 border-black data-[state=checked]:bg-transparent" />
                            <div className="grid gap-1.5 leading-none">
                              <label htmlFor="terms1" className="text-sm leading-none text-black peer-disabled:cursor-not-allowed peer-disabled:opacity-70 dark:text-neutral-400">
                                Remember me
                              </label>
                            </div>
                          </div>
                          <Link className="text-sm text-[#cd6011]" to="/">
                            Forgot Password?
                          </Link>
                          {/* <Link className="text-sm text-[#cd6011]" to="#" onClick={() => setMagicLinkDlg(true)}>
                            Login with Magic Link
                          </Link> */}
                          <a className="text-sm text-[#cd6011]" href="/aws-login">Login with Magic Link</a>
                        </div>
                        <Button type="submit" variant="default" className="mb-5 w-full bg-[#08163e] text-white hover:bg-[#08163e]" disabled={!isValid || isSubmitting}>
                          <span className="login-bt-text">{isSubmitting ? 'Logging in...' : 'Log In'}</span>
                        </Button>
                      </form>
                      {/* <p className="text-center">
                        <span className="text-black  dark:text-neutral-400">Don't have an account? </span>
                        <Link to="#" onClick={() => setEarlyAccessDlgOpen(true)} className="text-sm font-bold text-[#CE6112]">
                          Sign Up
                        </Link>
                      </p> */}
                      <p className="mt-5 w-full text-center text-xs font-light text-black">By using this app, you agree to our Terms & Conditions & Privacy Policy</p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        ) : null}

        {/* Magic lick popup */}
        <Dialog open={magicLinkDlg}>
          <DialogContent showCloseButton={false} className="max-w-[90vw] lg:max-w-xl">
            <DialogHeader>
              <DialogTitle className='mb-5 flex'>
                <div className='flex-1'>Login with Magic Link</div>
                <button onClick={() => setMagicLinkDlg(false)}>X</button>
              </DialogTitle>
            </DialogHeader>
            <AWSCognitoMain />
          </DialogContent>
        </Dialog>
      </DisableScrollY>
    </>
  );
};

export default Login;
