import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useAuth } from './AuthProvider';
import Logo from './branding/Logo';
import SideNav from './dashboard/SideNav';
import TopBar from './dashboard/TopBar';
import WelcomeHeading from './dashboard/WelcomeHeading';
// import Splash from './splash/Splash';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { CHECK_USER_PERMISSION_API_URL } from '@/config/config';
import { useLocation, useNavigate } from 'react-router-dom';
import user1 from '../assets/10.jpg';
import user2 from '../assets/13.jpg';
import user3 from '../assets/17.jpg';
import user4 from '../assets/44.jpg';
import user5 from '../assets/58.jpg';
import EnvelopeIcon from './icons/EnvelopeIcon';
import GlobeIcon from './icons/GlobeIcon';
import MapPointIcon from './icons/MapPointIcon';
import PhoneIcon from './icons/PhoneIcon';
import UnAuthorized from './unauthorized';

export interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const [, setLoading] = useState(true);
  const [logoutFlag, setLogoutFlag] = useState(false);
  const [useWelcomeTopBar, setUseWelcomeTopBar] = useState(true);
  const [showFundButton, setShowFundButton] = useState(true);
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const rightSideBar = location.pathname.split('/')[2] || '';

  // const parsed = queryString.parse(window.location.search);
  // const value = parsed.key;
  // const queryParams = new URLSearchParams(window.location.search);
  // const value = queryParams.get('name');
  // const [queryParameters] = useSearchParams()
  // const getAccessibleMenu = (access: string) => {
  //   if (access.includes('admin')) {
  //     return menuRoute; // Admins have access to all routes
  //   }

  //   const accessibleRoutes = [];
  //   if (access.includes('buying')) {
  //     accessibleRoutes.push('buyers');
  //   }
  //   if (access.includes('selling')) {
  //     accessibleRoutes.push('offers');
  //   }

  //   return accessibleRoutes;
  // };

  useLayoutEffect(() => {
    if (auth) {
      fetch(`${CHECK_USER_PERMISSION_API_URL}?cognitoID=${auth.sub}`)
        .then((response) => response.json())
        .then((authData) => {
          if (authData.statusCode === 200) {
            const { roles, permissions }: { roles: string[], permissions: string[] } = authData.body;
            localStorage.setItem('userRoles', JSON.stringify(roles));
            localStorage.setItem('userPermissions', JSON.stringify(permissions));

            const checkAccess = () => {
              if (roles.includes('admin')) {
                return true; // Admin has access to all routes
              } else if (roles.includes('buying') && (location.pathname.includes('buyers') || location.pathname.includes('me') || location.pathname.includes('settings'))) {
                return true; // Buying access
              } else if (roles.includes('selling') && (location.pathname.includes('offers') || location.pathname.includes('me') || location.pathname.includes('settings'))) {
                return true; // Selling access
              } else if (
                // Buying & Selling access
                roles.includes('buying') &&
                roles.includes('selling') &&
                (location.pathname.includes('buyers') || location.pathname.includes('offers') || location.pathname.includes('me') || location.pathname.includes('settings'))
              ) {
                return true;
              } else if (permissions.includes('view-property-search') && location.pathname.includes('properties')) {
                return true; // Permission 'view-property-search' for property search
              }

              setLogoutFlag(true);
              return false;
            };

            checkAccess();
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, [auth, location.pathname]);

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/me' || location.pathname === '/settings' || location.pathname === '/investments') {
      setUseWelcomeTopBar(true);
    } else if (location.pathname === '/lender-profiles' || location.pathname.includes('/lender-profiles/details/') || location.pathname.includes('/deals')) {
      setUseWelcomeTopBar(false);
      setShowFundButton(!location.pathname.includes('/deals'));
    }
  }, [location]);

  useEffect(() => {
    if (auth === undefined) {
      setTimeout(() => setLoading(true), 500);
    } else if (auth === null) {
      navigate('/logout', { replace: true });
    } else {
      setTimeout(() => setLoading(false), 2000);
    }
  }, [auth]);

  return (
    <div className="flex h-screen w-screen lg:bg-dash">
      <div className="fixed z-50 flex h-full w-full flex-col items-center gap-4 bg-dash p-5 text-center xxs:hidden">
        <Logo className="w-[75px]" />
        <p>Your device is too small for this app.</p>
        <p>Use a different device to get the full Icon Capital Management experience.</p>
      </div>
      {/* {loading && <Splash suppressInstallPrompt quickSplash />} */}
      <SideNav className="!hidden lg:!flex" />
      {logoutFlag ? (
        <div className="flex grow flex-col overflow-hidden">
          <div className="px-4 py-4 lg:px-7 lg:pb-4 lg:pt-5"  />
          <div className="flex grow flex-col overflow-y-auto p-4 pt-0 lg:px-7 lg:pb-8">
            {useWelcomeTopBar && <WelcomeHeading className="mb-4 block xl:hidden" />}
            <div className="flex grow">
              <UnAuthorized />
            </div>
          </div>
          <hr />
        </div>
      ) : (
        <div className="flex grow flex-col overflow-hidden">
          <TopBar className="px-4 py-4 lg:px-7 lg:pb-4 lg:pt-5" addWelcome={useWelcomeTopBar} showFundButton={showFundButton} />
          <div className="flex grow flex-col overflow-y-auto p-4 pt-0 lg:px-7 lg:pb-8">
            {useWelcomeTopBar && <WelcomeHeading className="mb-4 block xl:hidden" />}
            <div className="flex grow">{children}</div>
          </div>
          <hr />
        </div>
      )}
      {rightSideBar === 'details' && (
        <div className="flex  flex-col">
          {/* grow */}
          <div className="flex w-full  flex-col lg:w-96">
            <div className="mt-30 ml-3 flex shrink-0 grow flex-col border border-transparent drop-shadow-lg lg:mt-20">
              <Accordion defaultValue={['item-1', 'item-2', 'item-3']} type="multiple">
                <AccordionItem value="item-1" className="border-none">
                  <div className="mr-8 flex flex-col rounded-lg px-5 lg:bg-card">
                    <AccordionTrigger>
                      <h3 className="py-1 text-lg !font-normal leading-none tracking-tight lg:bg-card lg:text-xl lg:!font-medium">Company Info</h3>
                    </AccordionTrigger>
                  </div>
                  <AccordionContent data-state="open">
                    <div className="color: rgb(161, 163, 167) mr-8 flex flex-col px-5">
                      <div className="flex items-center justify-start">
                        <div>
                          <PhoneIcon />
                        </div>
                        <div className="pl-3">
                          <p className="text-md pt-4 !font-normal leading-none lg:text-base lg:!font-normal">Phone</p>
                          <p className="text-md lg:!font-extrathin pb-3 pt-1 !font-normal leading-none text-[#788190] lg:text-base">(212) 555-1212</p>
                        </div>
                      </div>
                    </div>
                    <div className="color: rgb(161,  163, 167)  mr-8 flex flex-col px-5">
                      <div className="flex items-center justify-start">
                        <div>
                          <GlobeIcon />
                        </div>
                        <div className="pl-3">
                          <p className="text-md pt-2 !font-normal leading-none lg:text-base lg:!font-normal">Website</p>
                          <p className="text-md lg:!font-extrathin pb-3 pt-1 !font-normal leading-none text-[#788190] lg:text-base">www.iconequity.com</p>
                        </div>
                      </div>
                    </div>
                    <div className="color: rgb(161,  163, 167)  mr-8 flex flex-col px-5">
                      <div className="flex items-center justify-start">
                        <div>
                          <EnvelopeIcon />
                        </div>
                        <div className="pl-3">
                          <p className="text-md pt-2 !font-normal leading-none lg:text-base lg:!font-normal">Email</p>
                          <p className="text-md lg:!font-extrathin pb-3 pt-1 !font-normal leading-none text-[#788190] lg:text-base">support@iconequity.com</p>
                        </div>
                      </div>
                    </div>
                    <div className="color: rgb(161,  163, 167)  mr-8 flex flex-col px-5">
                      <div className="flex items-center justify-start">
                        <div>
                          <MapPointIcon />
                        </div>
                        <div className="pl-3">
                          <p className="text-md pt-2 !font-normal leading-none lg:text-base lg:!font-normal">Website</p>
                          <p className="text-md lg:!font-extrathin pb-3 pt-1 !font-normal leading-none text-[#788190] lg:text-base">www.iconequity.com</p>
                        </div>
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem value="item-2" className="border-none">
                  <div className="mr-8 flex flex-col rounded-lg px-5 lg:bg-card">
                    <AccordionTrigger>
                      <h3 className="py-1 text-lg !font-normal leading-none tracking-tight lg:bg-card lg:text-xl lg:!font-medium">Team Members</h3>
                    </AccordionTrigger>
                  </div>
                  <AccordionContent>
                    <div className="mt-3 flex shrink-0 grow flex-col border border-transparent drop-shadow-lg lg:mt-2">
                      <div className="color: rgb(161, 163, 167)  mr-8 flex flex-col px-5">
                        <div className="flex items-center justify-start">
                          <div className="flex items-center justify-start">
                            <img src={user1} alt="David Reynolds" className="h-auto w-10 max-w-full rounded-full" />
                          </div>
                          <div className="pl-3">
                            <p className="text-md pt-6 !font-normal leading-none lg:text-base lg:!font-normal">David Reynolds</p>
                            <p className="text-md lg:!font-extrathin pb-1 pt-1 !font-normal leading-none text-[#788190] lg:text-base">Financial Data Scientist</p>
                          </div>
                        </div>
                      </div>
                      <div className="color: rgb(161, 163, 167)  mr-8 flex flex-col px-5">
                        <div className="flex items-center justify-start">
                          <div>
                            <img src={user2} alt="David Reynolds" className="h-auto w-10 max-w-full rounded-full" />
                          </div>
                          <div className="pl-3">
                            <p className="text-md pt-6 !font-normal leading-none lg:text-base lg:!font-normal">David Lora</p>
                            <p className="text-md lg:!font-extrathin pb-1 pt-1 !font-normal leading-none text-[#788190] lg:text-base">Operations Research Analyst</p>
                          </div>
                        </div>
                      </div>
                      <div className="color: rgb(161, 163, 167)  mr-8 flex flex-col px-5">
                        <div className="flex items-center justify-start">
                          <div>
                            <img src={user3} alt="David Reynolds" className="h-auto w-10 max-w-full rounded-full" />
                          </div>
                          <div className="pl-3">
                            <p className="text-md pt-6 !font-normal leading-none lg:text-base lg:!font-normal">Emily Lee</p>
                            <p className="text-md lg:!font-extrathin pb-1 pt-1 !font-normal leading-none text-[#788190] lg:text-base">Operations Research Analyst</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem value="item-3">
                  <div className="mr-8 flex flex-col rounded-lg px-5 lg:bg-card">
                    <AccordionTrigger>
                      <h3 className="py-1 text-lg !font-normal leading-none tracking-tight lg:bg-card lg:text-xl lg:!font-medium">Investors</h3>
                    </AccordionTrigger>
                  </div>
                  <AccordionContent>
                    <div className="mt-5 flex shrink-0 grow flex-col border border-transparent drop-shadow-lg lg:mt-2">
                      <div className="color: rgb(161, 163, 167)  mr-8 flex flex-col px-5">
                        <div className="flex items-center justify-start">
                          <div className="flex items-center justify-start">
                            <img src={user5} alt="David Reynolds" className="h-auto w-10 max-w-full rounded-full" />
                          </div>
                          <div className="pl-3">
                            <p className="text-md pt-6 !font-normal leading-none lg:text-base lg:!font-normal">Karen Austin</p>
                            <p className="text-md lg:!font-extrathin pb-1 pt-1 !font-normal leading-none text-[#788190] lg:text-base">Investor</p>
                          </div>
                        </div>
                      </div>
                      <div className="color: rgb(161, 163, 167)  mr-8 flex flex-col px-5">
                        <div className="flex items-center justify-start">
                          <div>
                            <img src={user4} alt="EunJeong Kim" className="h-auto w-10 max-w-full rounded-full" />
                          </div>
                          <div className="pl-3">
                            <p className="text-md pt-6 !font-normal leading-none lg:text-base lg:!font-normal">EunJeong Kim</p>
                            <p className="text-md lg:!font-extrathin pb-1 pt-1 !font-normal leading-none text-[#788190] lg:text-base">Investor</p>
                          </div>
                        </div>
                      </div>
                      <div className="color: rgb(161, 163, 167)  mr-8 flex flex-col px-5">
                        <div className="flex items-center justify-start">
                          <div>
                            <img src={user3} alt="David Reynolds" className="h-auto w-10 max-w-full rounded-full" />
                          </div>
                          <div className="pl-3">
                            <p className="text-md pt-6 !font-normal leading-none lg:text-base lg:!font-normal">Emily Lee</p>
                            <p className="text-md lg:!font-extrathin pb-1 pt-1 !font-normal leading-none text-[#788190] lg:text-base">Investor</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
          <div className="flex h-full flex-col"></div>
        </div>
      )}
    </div>
  );
};

export default Layout;
